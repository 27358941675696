import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Account } from '../schema/account/account';
import { getDaysToExpiry } from '../lib/getDaysToExpiry';
import { FeedbackBar } from './FeedbackBar';
import { getPhpSitePath } from '../lib/getPhpSitePath';

import './FeedbackFreeTrialStatus.scss';

type FeedbackFreeTrialProps = {
  account: Account | null;
  className?: string;
  shouldShowFreeExperienceFeedback?: boolean;
};

export function FeedbackFreeTrialStatus({
  account,
  className,
  shouldShowFreeExperienceFeedback
}: FeedbackFreeTrialProps) {
  const sharedClass = 'feedback-free-trial-status';
  const format = 'section';
  const subscribeUrl = getPhpSitePath(
    `account/payment?relocate=${encodeURIComponent(window.location.pathname)}`
  );

  // if no account, no dates, or not trial subscription, return null
  if (
    account === null ||
    account.subscriptionExpiryDate === null ||
    account.subscriptionType !== 'trial'
  ) {
    return null;
  }

  const daysToExpiry = getDaysToExpiry(account.subscriptionExpiryDate);
  const isStartDate = moment(account.subscription?.startDate).isSame(
    new Date(),
    'day'
  );

  if (daysToExpiry < -7) {
    return shouldShowFreeExperienceFeedback ? (
      <FeedbackBar
        className={classnames(sharedClass, className)}
        format={format}
        headline="You are currently using a free account."
        message="To get the full EM:RAP experience, consider subscribing."
        variant="neutral"
        actionText="Subscribe now"
        href={subscribeUrl}
      />
    ) : null;
  }

  // trial recently expired
  if (daysToExpiry < 0) {
    return (
      <FeedbackBar
        className={classnames(sharedClass, className)}
        format={format}
        headline="Your free trial has expired."
        message="You’ve been downgraded to a free account. To get the full EM:RAP experience, consider subscribing."
        variant="negative"
        actionText="Subscribe now"
        href={subscribeUrl}
      />
    );
  }

  // first sign in after trial sign up
  if (isStartDate) {
    return (
      <FeedbackBar
        className={classnames(sharedClass, className)}
        format={format}
        headline="Your free trial has started!"
        message={`Get a taste of all EM:RAP has to offer for the next ${daysToExpiry} days`}
        variant="positive"
        actionText="Upgrade now"
        href={subscribeUrl}
      />
    );
  }

  // trial active
  return (
    <FeedbackBar
      className={classnames(sharedClass, className)}
      format={format}
      headline={`Your free trial will expire in ${daysToExpiry} ${
        daysToExpiry === 1 ? 'day' : 'days'
      }`}
      message="Upgrade now to keep using EM:RAP without interruption."
      variant={daysToExpiry <= 7 ? 'warning' : 'neutral'}
      actionText="Upgrade now"
      href={subscribeUrl}
    />
  );
}
