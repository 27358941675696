import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

export function SignedOut() {
  const { trialSubscriptionMessaging } = useFlags();
  return (
    <div className="status-bar">
      <div className="status-bar__container">
        <p className="status-bar__text">
          <a
            href={getPhpSitePath(
              trialSubscriptionMessaging
                ? '/account/sign-up?source=free-trial-callout'
                : '/subscribe'
            )}
          >
            Start with a free trial account
          </a>{' '}
          for free content every month. Already a subscriber?{' '}
          <a href={getPhpSitePath('/account/sign-in')}>Sign in</a>
        </p>
      </div>
    </div>
  );
}
