/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountState } from '../lib/stateTypes';
import { Account, accountSchema } from '../schema/account/account';
import { EventApi, getAccountSchema } from '../schema/api/getAccount';
import { Subscription } from '../schema/account/subscription';
import { EventSubscription } from '../schema/account/eventSubscription';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';
import { ApiError } from '../lib/ApiError';

export const initialState: AccountState = {
  account: null,
  isLoading: false,
  hasError: false
};

export const payloadCreator = async () => {
  const errorContext: { extra: { [key: string]: unknown } } = {
    extra: {
      url: `get_account?include=subscriptions,events`
    }
  };

  try {
    const response = await fetchApi(
      'get_account?include=subscriptions,events',
      { baseUrl: process.env.REACT_APP_API_URI }
    );

    const data = await response.json();

    const parsedData = getAccountSchema.parse(data);

    if (parsedData.accounts && parsedData.accounts.length > 0) {
      const {
        meta: { timeZone, isSpoofed }
      } = parsedData;
      const accountData = parsedData.accounts[0];

      const events: EventSubscription[] = Array.isArray(parsedData.events)
        ? parsedData.events.map(
            ({
              shortname,
              has_in_person: hasInPerson,
              has_streaming: hasStreaming
            }: EventApi) => ({
              shortname,
              hasInPerson: Boolean(hasInPerson),
              hasStreaming: Boolean(hasStreaming)
            })
          )
        : [];

      const account: Account = {
        id: accountData.id,
        avatarUri: accountData.avatarURI,
        firstName: accountData.firstName,
        lastName: accountData.lastName,
        suffix: accountData.suffix ?? null,
        email: accountData.email,
        specialty: accountData.specialty,
        isSpoofed,
        events,
        subscription: null,
        isCurator: accountData.isCurator,
        edition: accountData.edition,
        subscriptionType: accountData.subscriptionType,
        subscriptionExpiryDate: accountData.subscriptionExpiryDate
      };

      if (parsedData.subscriptions && parsedData.subscriptions.length > 0) {
        const subscriptionData = parsedData.subscriptions[0];

        const subscription: Subscription = {
          id: subscriptionData.id,
          title: subscriptionData.title,
          type: subscriptionData.type,
          startDate: moment
            .tz(subscriptionData.startDate, timeZone)
            .toDate()
            .toJSON(),
          expiryDate:
            subscriptionData.expiryDate === null
              ? undefined
              : moment
                  .tz(subscriptionData.expiryDate, timeZone)
                  .toDate()
                  .toJSON(),
          automaticRenewalStatus: subscriptionData.automaticRenewalStatus,
          cardExpiryDate:
            subscriptionData.cardExpiryDate === null
              ? undefined
              : moment
                  .tz(subscriptionData.cardExpiryDate, timeZone)
                  .toDate()
                  .toJSON()
        };

        account.subscription = subscription;
      }

      try {
        return accountSchema.parse(account);
      } catch (error) {
        errorContext.extra.tags = { id: account.id, email: account.email };
        throw error;
      }
    }

    return null;
  } catch (error) {
    if (error instanceof ApiError) {
      errorContext.extra.status = error.status;

      if (error.status !== 401) {
        reportError(error, errorContext);
        throw error;
      }
    }
    reportError(error, errorContext);
    return null;
  }
};

export const loadAccount = createAsyncThunk<
  Account | null,
  void,
  {
    rejectValue: null;
  }
>('account/loadAccount', payloadCreator);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loadSuccess: (state, action: PayloadAction<Account | null>) => {
      state.account = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadAccount.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loadAccount.fulfilled, (state, action) => {
      state.account = action.payload;
      state.isLoading = false;
    });
    builder.addCase(loadAccount.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.account = initialState.account;
      state.hasError = true;
      state.error = action.error.message;
    });
  }
});

export const { loadSuccess } = accountSlice.actions;
