import React from 'react';
import { useSelector } from '../../lib/hooks';
import { FeedbackBar } from '../FeedbackBar';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { getDaysToExpiry } from '../../lib/getDaysToExpiry';

interface TrialSubscriptionStatusProps {
  tabName: string;
}

export function TrialSubscriptionStatus({
  tabName
}: TrialSubscriptionStatusProps) {
  const account = useSelector(state => state.account.account);

  if (!account || account.subscriptionExpiryDate === null) {
    return null;
  }

  const isTrialActive = getDaysToExpiry(account.subscriptionExpiryDate) >= 0;

  if (tabName === 'conversation') {
    return (
      <FeedbackBar
        className="subscription-status__trial-feedback"
        format="inline"
        variant="warning"
        message={`You can read but not contribute to comments with a free ${
          isTrialActive ? 'trial' : 'account'
        }.`}
        href={getPhpSitePath(
          `/account/payment?relocate=${encodeURIComponent(
            window.location.pathname
          )}`
        )}
        actionText="Upgrade now"
      />
    );
  }

  if (tabName === 'cme') {
    return (
      <FeedbackBar
        className="subscription-status__trial-feedback"
        format="inline"
        message={`You can view but not claim CME with a free ${
          isTrialActive ? 'trial' : 'account'
        }.`}
        variant="warning"
        actionText="Upgrade now"
        href={getPhpSitePath(
          `/account/payment?relocate=${encodeURIComponent(
            window.location.pathname
          )}`
        )}
      />
    );
  }

  return null;
}
