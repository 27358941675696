import React from 'react';
import classnames from 'classnames';
import Loader from '../Loader';
import { WebComment } from '../../schema/webComment/webComment';
import { ChapterCommentStatus } from '../../schema/webEpisode/webChapter';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import organizeComments from '../../lib/organizeComments';
import ConversationComment from './ConversationComment';
import ConversationCommentForm from './ConversationCommentForm';
import SubscriptionStatus from '../ChapterPage/SubscriptionStatus';
import { useSelector } from '../../lib/hooks';

import './Conversation.scss';

interface ConversationProps {
  comments: WebComment[];
  isLoading: boolean;
  chapterId: number;
  episodeId: number;
  chapterCommentStatus: ChapterCommentStatus;
  episodeType: EpisodeTypeShortname;
  isSignedIn: boolean;
  isPaidAndActive: boolean;
  isFreeAccount: boolean;
  isTrial: boolean;
}

export const Conversation = ({
  comments,
  isLoading,
  chapterId,
  episodeId,
  chapterCommentStatus,
  episodeType,
  isSignedIn,
  isPaidAndActive,
  isFreeAccount,
  isTrial
}: ConversationProps) => {
  const account = useSelector(state => state.account);
  const gravatarEmail = account?.account?.email;
  const accountId = account?.account?.id;

  if (isLoading) {
    return <Loader />;
  }

  const orderedComments = organizeComments(comments);

  return (
    <div className={classnames('conversation', `conversation--${episodeType}`)}>
      {chapterCommentStatus === ChapterCommentStatus.LOCKED && (
        <div className="conversation__locked-message">
          Comments for this are now closed
        </div>
      )}
      {orderedComments.map((comment, index) => (
        <React.Fragment key={comment.id}>
          <ConversationComment
            comment={comment}
            chapterId={chapterId}
            episodeId={episodeId}
            chapterCommentStatus={chapterCommentStatus}
            gravatarEmail={gravatarEmail || null}
            accountId={accountId}
            episodeType={episodeType}
            isPaidAndActive={isPaidAndActive}
            isTrial={isTrial}
          />
          {index < orderedComments.length - 1 && (
            <hr className="conversation__separator" />
          )}
        </React.Fragment>
      ))}
      {isPaidAndActive &&
        !isTrial &&
        chapterCommentStatus !== ChapterCommentStatus.LOCKED && (
          <ConversationCommentForm
            episodeId={episodeId}
            chapterId={chapterId}
            gravatarEmail={gravatarEmail || null}
            isReply={false}
            parentCommentId={null}
            episodeType={episodeType}
          />
        )}
      <SubscriptionStatus
        tabName="conversation"
        isSignedIn={isSignedIn}
        isPaidAndActive={isPaidAndActive}
        isFreeAccount={isFreeAccount}
        isTrial={isTrial}
        episodeType={episodeType}
      />
    </div>
  );
};

export default Conversation;
